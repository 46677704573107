export const platforms = [
    {
        logo: "https://icons.defined.fi/networks/sanko.png",
        name: "Sanko 420",
        link: "https://sanko420.com/"
    },
    {
        logo: "https://icons.defined.fi/networks/arbitrum.png",
        name: "Arbitrum 420",
        link: "https://arbitrum420.com/"
    },
    {
        logo: "https://icons.defined.fi/networks/ethereum.png",
        name: "Ethereum 420",
        link: "https://ethereum420.net/"
    },
    {
        logo: "https://icons.defined.fi/explorers/basescan.png",
        name: "Base 420",
        link: "https://base420.xyz/"
    },
    {
        logo: "/apecoin-ape-ape-logo.png",
        name: "APE 420",
        link: "https://ape420.xyz"
    }
];