export interface Participant {
    id: number;
    name: string;
    address: string;
}

export enum ChatType {
    GROUP = "GROUP",
    PRIVATE = "PRIVATE",
    PUBLIC = "PUBLIC"
}


export interface ChatRoom {
    id: number;
    name: string;
    subtitle: string;
    avatar: string | null;
    unreadCount: number;
    participants: Participant[];
    creator?: Participant;
    chatType: ChatType
}