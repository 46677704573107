import { Route, Routes } from 'react-router-dom';
import Admin from './Admin';
import Game from './Game';
import Help from './Help';
import Leaderboard from './Leaderboard';
import { useWallet } from './context/WalletContext';
import ChatMessenger from './ChatMessenger';
import Layout from './layouts/main';
import ChatArea from './ChatArea';

export const App = () => {
  const { account } = useWallet();
  return (
    <>
      <Routes>
        <Route path="/" element={<Game />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/help" element={<Help />} />
        <Route path="/leaderboard" element={<Leaderboard />} />
        <Route path="/chats" element={account ? <ChatMessenger /> : <Layout children />} />
        <Route path="/chat-area/:roomId" element={account ? <ChatArea /> : <Layout children />} />
        <Route path="*" element={<h1>404: Page Not Found</h1>} />
      </Routes>
    </>
  );
};
