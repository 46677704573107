import { Info, Delete } from "@mui/icons-material";
import {
    Avatar,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
    Grid
} from "@mui/material";
import React, { useState } from "react";
import { Participant } from "../shared/ChatRoom";

interface ParticipantsDialogProps {
    open: boolean;
    onClose: () => void;
    participants: Participant[];
    onRemoveParticipant: (participant: Participant) => void;
}

const ParticipantsDialog: React.FC<ParticipantsDialogProps> = ({
    open,
    onClose,
    participants,
    onRemoveParticipant,
}) => {
    const [selectedParticipant, setSelectedParticipant] = useState<Participant | null>(null);
    const [confirmOpen, setConfirmOpen] = useState(false);

    const handleRemoveClick = (participant: Participant) => {
        setSelectedParticipant(participant);
        setConfirmOpen(true);
    };

    const handleConfirmRemove = () => {
        if (selectedParticipant) {
            onRemoveParticipant(selectedParticipant);
        }
        setConfirmOpen(false);
        setSelectedParticipant(null);
    };

    const handleCancelRemove = () => {
        setConfirmOpen(false);
        setSelectedParticipant(null);
    };

    return (
        <>
            <Dialog open={open} onClose={onClose} fullWidth>
                <DialogTitle>
                    <Box display="flex" alignItems="center">
                        <Info sx={{ marginRight: 1 }} />
                        <Typography variant="h6">Participants of Group</Typography>
                    </Box>
                </DialogTitle>
                <DialogContent dividers sx={{ padding: '10px' }}>
                    <List>
                        {participants.map((participant) => (
                            <ListItem key={participant.id} divider sx={{ paddingRight: '50px' }}>
                                <ListItemAvatar>
                                    <Avatar>{participant.name.charAt(0)}</Avatar>
                                </ListItemAvatar>
                                <Grid container alignItems="center">
                                    <Grid item xs>
                                        <ListItemText
                                            primary={participant.name}
                                            secondary={participant.address}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <IconButton
                                            edge="end"
                                            sx={{ color: 'red' }}
                                            onClick={() => handleRemoveClick(participant)}
                                        >
                                            <Delete />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </ListItem>
                        ))}
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Confirmation Dialog for Removal */}
            <Dialog
                open={confirmOpen}
                onClose={handleCancelRemove}
            >
                <DialogTitle>Confirm Removal</DialogTitle>
                <DialogContent>
                    <Typography>
                        Are you sure you want to remove {selectedParticipant?.name} from the group?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelRemove} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirmRemove} color="secondary">
                        Remove
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ParticipantsDialog;
