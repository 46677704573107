export const marketEnumMapping: { [key: string]: number } = {
    BTC: 0,
    ETH: 1,
    ARB: 2,
    APE: 3,
    SOL: 4,
};

export const TokenEnumMapping: { [key: string]: number } = {
    SP: 0,
    SILVER: 1,
    GOLD: 2
};

export const reverseMarketEnumMapping: { [key: number]: string } = Object.fromEntries(
    Object.entries(marketEnumMapping).map(([key, value]) => [value, key])
);

export const reverseTokenEnumMapping: { [key: number]: string } = Object.fromEntries(
    Object.entries(TokenEnumMapping).map(([key, value]) => [value, key])
);