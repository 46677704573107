import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  RainbowKitProvider,
  Theme
} from '@rainbow-me/rainbowkit';
import '@rainbow-me/rainbowkit/styles.css';
import {
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { SnackbarProvider } from 'notistack';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { WagmiProvider } from 'wagmi';
import { App } from './App';
import { SimpleBackdrop } from './components';
import { LoaderProvider } from './context/LoaderContext';
import { WalletProvider } from './context/WalletContext';
import reportWebVitals from './reportWebVitals';
import { wagmiConfig } from './wagmiConfig';

console.log(process.env.REACT_APP_RPC);




const queryClient = new QueryClient();

const myCustomTheme: Theme = {
  blurs: {
    modalOverlay: 'rgba(0, 0, 0, 0.6)',
  },
  colors: {
    accentColor: '#39FF14',
    accentColorForeground: '#000000',
    actionButtonBorder: '#222222',
    actionButtonBorderMobile: '#222222',
    actionButtonSecondaryBackground: '#222222',
    closeButton: '#39FF14',
    closeButtonBackground: '#1A1A1A',
    connectButtonBackground: '#39FF14',
    connectButtonBackgroundError: '#FF4C4C',
    connectButtonInnerBackground: '#1A1A1A',
    connectButtonText: '#000000',
    connectButtonTextError: '#FFFFFF',
    connectionIndicator: '#39FF14',
    downloadBottomCardBackground: '#1A1A1A',
    downloadTopCardBackground: '#222222',
    error: '#FF4C4C',
    generalBorder: '#444444',
    generalBorderDim: '#444444',
    menuItemBackground: '#1A1A1A',
    modalBackdrop: 'rgba(0, 0, 0, 0.8)',
    modalBackground: '#1A1A1A',
    modalBorder: '#444444',
    modalText: '#FFFFFF',
    modalTextDim: '#CCCCCC',
    modalTextSecondary: '#39FF14',
    profileAction: '#39FF14',
    profileActionHover: '#2BEA00',
    profileForeground: '#1A1A1A',
    selectedOptionBorder: '#444444',
    standby: '#39FF14'
  },
  fonts: {
    body: 'MayfairNbpBold, sans-serif',
  },
  radii: {
    actionButton: '8px',
    connectButton: '8px',
    menuButton: '4px',
    modal: '12px',
    modalMobile: '12px'
  },
  shadows: {
    connectButton: '0 0px 0px rgba(0, 0, 0, 0)',
    dialog: '0 8px 20px rgba(0, 0, 0, 0)',
    profileDetailsAction: '0 4px 8px rgba(0, 0, 0, 0)',
    selectedOption: '0 0 10px rgba(57, 255, 20, 0)',
    selectedWallet: '0 0 10px rgba(57, 255, 20, 0)',
    walletLogo: '0 4px 8px rgba(0, 0, 0, 0)',
  },
};


const theme = createTheme({
  typography: {
    fontFamily: 'MayfairNbpBold',
    fontSize: 18,
    h1: { fontFamily: 'MayfairNbpBold', color: '#39FF14', fontSize: '2.5rem' },
    h2: { fontFamily: 'MayfairNbpBold', color: '#39FF14', fontSize: '2rem' },
    h3: { fontFamily: 'MayfairNbpBold', color: '#39FF14', fontSize: '1.75rem' },
    h4: { fontFamily: 'MayfairNbpBold', color: '#39FF14', fontSize: '1.5rem' },
    h5: { fontFamily: 'MayfairNbpBold', color: '#39FF14', fontSize: '1.25rem' },
    h6: { fontFamily: 'MayfairNbpBold', color: '#39FF14', fontSize: '1rem' },
    body1: { fontFamily: 'MayfairNbpBold', color: '#39FF14' },
    body2: { fontFamily: 'MayfairNbpBold', color: '#39FF14', fontSize: '1.5rem' },
  },
  palette: {
    background: {
      default: 'black',
      paper: '#1a1a1a',
    },
    text: {
      primary: '#39FF14',
      secondary: 'white',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: '#39FF14',
          color: 'black',
          '&:hover': {
            backgroundColor: '#2BEA00',
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          backgroundColor: '#1a1a1a',
          color: 'white',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          color: '#39FF14',
          fontSize: '2rem',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          color: '#39FF14',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          color: '#39FF14',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: 'black',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#39FF14',
            },
            '&:hover fieldset': {
              borderColor: '#39FF14',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#39FF14',
            },
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#39FF14',
          '&.Mui-focused': {
            color: '#39FF14',
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: '#39FF14',
        },
      },
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <WagmiProvider config={wagmiConfig}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider theme={myCustomTheme}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <SnackbarProvider maxSnack={3}>
              <WalletProvider>
                <LoaderProvider>
                  <SimpleBackdrop />
                  <BrowserRouter>
                    <App />
                  </BrowserRouter>
                </LoaderProvider>
              </WalletProvider>
            </SnackbarProvider>
          </ThemeProvider>
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  </React.StrictMode>
);

reportWebVitals();
