import { createContext, ReactNode, useContext } from 'react';
import { useAccount } from 'wagmi';

interface WalletContextType {
    account: string | undefined;
    connected: boolean;
}

const WalletContext = createContext<WalletContextType | undefined>(undefined);

export const WalletProvider = ({ children }: { children: ReactNode }) => {
    const { address, isConnected } = useAccount();

    const connected = isConnected;

    return (
        <WalletContext.Provider value={{ account: address, connected }}>
            {children}
        </WalletContext.Provider>
    );
};

export const useWallet = () => {
    const context = useContext(WalletContext);
    if (!context) {
        throw new Error('useWallet must be used within a WalletProvider');
    }
    return context;
};
