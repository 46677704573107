export const handleMetaMaskError = (error: any): string => {
    const code = error?.code;
    const message = error?.message;

    // Handle common EIP-1193 errors (MetaMask uses this standard)
    switch (code) {
        case 4001: // User rejected the request
            return "User rejected the request. Please authorize the transaction.";
        case -32002: // Pending request already exists
            return "A request is pending. Please confirm the previous request.";
        case -32603: // Internal JSON-RPC error
            if (message?.includes('insufficient funds')) {
                return "Insufficient funds. Please ensure you have enough balance.";
            } else if (message?.includes("execution reverted")) {
                const revertReason = extractRevertReason(error);
                return revertReason || "Smart contract execution reverted. Check your inputs or balance.";
            }
            return "An internal error occurred. Please try again.";
        default:
            break;
    }

    // Handle specific messages and cases
    if (message) {
        if (message.includes('user denied transaction signature')) {
            return "Transaction denied. Please authorize the transaction.";
        } else if (message.includes('missing provider')) {
            return "Ethereum provider not found. Please install MetaMask.";
        } else if (message.includes("invalid EIP-1193 provider")) {
            return "Ethereum provider not found. Please install MetaMask and try again.";
        } else if (message.includes("could not decode result data")) {
            return "Change your MetaMask network to the correct chain.";
        } else if (message.includes("nonce too low")) {
            return "Transaction nonce is too low. Try increasing the gas or reset your nonce.";
        } else if (message.includes("gas required exceeds allowance")) {
            return "The gas required exceeds the allowance. Consider adjusting your gas limit.";
        } else if (message.includes("transaction underpriced")) {
            return "Gas price too low. Try increasing the gas price.";
        } else if (message.includes("transaction failed")) {
            return "Transaction failed. Ensure you have enough gas and check your inputs.";
        } else if (message.includes("execution reverted")) {
            const revertReason = extractRevertReason(error);
            return revertReason || "Transaction reverted by the EVM. Check the smart contract logic or your input values.";
        }
    }

    // Fallback to the generic error message if no specific case matches
    return message || "An unknown error occurred.";
};

// Helper function to extract revert reason from error object
const extractRevertReason = (error: any): string | null => {
    const revertReasonRegex = /execution reverted: (.+)/;
    const match = error?.message?.match(revertReasonRegex);
    return match ? match[1] : null;
};
