import ChatIcon from '@mui/icons-material/Chat';
import { Fab, Badge } from '@mui/material';
import { useNavigate } from "react-router-dom";

// Define props type
interface ChatButtonProps {
    unreadCount: number;
}

const ChatButton: React.FC<ChatButtonProps> = ({ unreadCount }) => {
    const navigate = useNavigate();

    const handleChatClick = () => {
        navigate('/chats');
    };

    return (
        <Fab
            onClick={handleChatClick}
            sx={{
                position: 'fixed',
                bottom: 70,
                right: 25,
                zIndex: 1000,
            }}
        >
            <Badge
                badgeContent={unreadCount}
                color="secondary"
                overlap="circular"
            >
                <ChatIcon />
            </Badge>
        </Fab>
    );
};

export default ChatButton;
