import React, { useEffect, useRef, useState } from 'react';
import axios, { AxiosRequestConfig } from 'axios';
import { createChart, IChartApi, ISeriesApi, Time, CandlestickData } from 'lightweight-charts';
import { createHmac } from '../shared/createHmac';

interface ChartProps {
    symbol: string;
}

const Chart: React.FC<ChartProps> = ({ symbol }) => {
    const chartContainerRef = useRef<HTMLDivElement>(null);
    const chartRef = useRef<IChartApi | null>(null);
    const candleSeriesRef = useRef<ISeriesApi<'Candlestick'> | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    const fetchBarData = async () => {
        try {
            const data = {
                symbol,
            };
            const hmac = createHmac(JSON.stringify(data));

            const config: AxiosRequestConfig = {
                headers: {
                    'X-HMAC': `${hmac}`,
                    'Content-Type': 'application/json'
                }
            };

            const response = await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/bars`, data, config);
            const barsData = response.data.bars;

            const chartData: CandlestickData[] = barsData.o.map((open: number, index: number) => ({
                time: barsData.t[index],
                open: open,
                high: barsData.h[index],
                low: barsData.l[index],
                close: barsData.c[index],
            }));

            return chartData;
        } catch (error) {
            console.error('Error fetching data:', error);
            setError('Failed to fetch data');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const initializeChart = async () => {
            if (chartContainerRef.current && !chartRef.current) {
                const chart = createChart(chartContainerRef.current, {
                    width: chartContainerRef.current.clientWidth,
                    height: 300,
                    layout: {
                        background: { color: '#222' },
                        textColor: '#DDD',
                    },
                    grid: {
                        vertLines: { color: '#444' },
                        horzLines: { color: '#444' },
                    },
                    rightPriceScale: {
                        borderVisible: false,
                    },
                    timeScale: {
                        borderVisible: false,
                    },
                });
                chartRef.current = chart;

                const candleSeries = chart.addCandlestickSeries({
                    upColor: '#26a69a',
                    downColor: '#ef5350',
                    borderUpColor: '#26a69a',
                    borderDownColor: '#ef5350',
                    wickUpColor: '#26a69a',
                    wickDownColor: '#ef5350',
                });
                candleSeriesRef.current = candleSeries;

                const data = await fetchBarData();
                if (data) {
                    candleSeries.setData(data);

                    // Scroll to the most recent data (current day)
                    chart.timeScale().scrollToRealTime();
                }

                // Handle chart resize
                const resizeObserver = new ResizeObserver(() => {
                    if (chartContainerRef.current) {
                        chart.applyOptions({ width: chartContainerRef.current.clientWidth });
                    }
                });

                if (chartContainerRef.current) {
                    resizeObserver.observe(chartContainerRef.current);
                } else {
                    console.error('chartContainerRef is not assigned to a valid DOM element');
                }

                return () => {
                    resizeObserver.disconnect();
                    chart.remove();
                    chartRef.current = null; // Clear the reference
                };
            }
        };

        initializeChart();

        // Refetch data every 15 seconds
        const intervalId = setInterval(async () => {
            if (candleSeriesRef.current) {
                const data = await fetchBarData();
                if (data) {
                    candleSeriesRef.current.setData(data);

                    // Scroll to the most recent data on update
                    if (chartRef.current) {
                        chartRef.current.timeScale().scrollToRealTime();
                    }
                }
            }
        }, 10 * 1000); // 1000 ms = 1 second

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    return (
        <div>
            <div ref={chartContainerRef} style={{ position: 'relative', width: '100%', height: '300px' }} />
        </div>
    );
};

export default Chart;
