import { Language, Menu as MenuIcon, Telegram, Twitter } from '@mui/icons-material';
import {
    AppBar,
    Box,
    Button,
    Card,
    CardHeader,
    Drawer,
    IconButton,
    Link,
    List,
    ListItem,
    ListItemText,
    Menu,
    MenuItem,
    Toolbar,
    Typography
} from '@mui/material';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import axios, { AxiosRequestConfig } from 'axios';
import React, { ReactNode, useEffect, useState } from 'react';
import { Link as NavLink, useLocation } from 'react-router-dom';
import Chart from '../components/Chart';
import { useWallet } from '../context/WalletContext';
import { createHmac } from '../shared/createHmac';
import { platforms } from '../shared/platforms';
import ChatButton from '../components/ChatButton';
import { axiosGet } from '../shared/requests';


const navLinks = [
    { to: "/help", text: "Help" },
    { to: "/leaderboard", text: "Leaderboard" }
];

interface LayoutProps {
    children: ReactNode;
}

const CustomConnectButton = () => {
    return (
        <>
            <ConnectButton.Custom>
                {({
                    account,
                    chain,
                    openAccountModal,
                    openChainModal,
                    openConnectModal,
                    authenticationStatus,
                    mounted,
                }) => {
                    const ready = mounted && authenticationStatus !== 'loading';
                    const connected =
                        ready &&
                        account &&
                        chain &&
                        (!authenticationStatus ||
                            authenticationStatus === 'authenticated');

                    return (
                        <div
                            {...(!ready && {
                                'aria-hidden': true,
                                style: {
                                    opacity: 0,
                                    pointerEvents: 'none',
                                    userSelect: 'none',
                                },
                            })}
                        >
                            {(() => {
                                if (!connected) {
                                    return (
                                        <Button
                                            variant="contained"
                                            sx={{ marginLeft: 2 }}
                                            onClick={openConnectModal}
                                            type="button"
                                        >
                                            Connect Wallet
                                        </Button>
                                    );
                                }
                                if (chain.unsupported) {
                                    return (
                                        <Button
                                            variant="contained"
                                            sx={{ marginLeft: 2 }}
                                            onClick={openChainModal}
                                            type="button"
                                        >
                                            Wrong network
                                        </Button>
                                    );
                                }
                                return (
                                    <Button
                                        variant="contained"
                                        sx={{ marginLeft: 2 }}
                                        onClick={openAccountModal}
                                        type="button"
                                    >
                                        {account.displayName}
                                        {account.displayBalance
                                            ? ` (${account.displayBalance})`
                                            : ''}
                                    </Button>
                                );
                            })()}
                        </div>
                    );
                }}
            </ConnectButton.Custom>
        </>
    );
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [prices, setPrices] = useState<{ [key: string]: number }>({});
    const { account, connected } = useWallet();
    const [unreadCount, setUnreadCount] = useState(0);
    const location = useLocation();

    const [logoMenuAnchorEl, setLogoMenuAnchorEl] = useState<null | HTMLElement>(null);

    const handleLogoClick = (event: React.MouseEvent<HTMLElement>) => {
        setLogoMenuAnchorEl(event.currentTarget);
    };

    const handleCloseLogoMenu = () => {
        setLogoMenuAnchorEl(null);
    };


    const toggleDrawer = (open: boolean) => () => {
        setDrawerOpen(open);
    };

    const isActiveLink = (link: string) => location.pathname === link;

    const drawerLinks = (
        <List sx={{ height: "100%" }}>
            {navLinks.map((x, i) => (
                <NavLink to={x.to} key={i}>
                    <ListItem
                        sx={{
                            backgroundColor: isActiveLink(x.to) ? "#444" : "transparent",
                            "&:hover": {
                                backgroundColor: "#555",
                            },
                        }}
                        onClick={toggleDrawer(false)}>
                        <ListItemText primary={x.text} sx={{ color: "white" }} />
                    </ListItem>
                </NavLink>
            ))}
        </List>
    );

    const fetchPrices = async () => {
        const hmac = createHmac('{}');
        const config: AxiosRequestConfig = {
            headers: {
                'X-HMAC': `${hmac}`,
                'Content-Type': 'application/json',
            },
        };

        try {
            const response = await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/allprices`, {}, config);
            const priceData = response.data.prices.reduce((acc: { [key: string]: number }, item: { symbol: string; price: number }) => {
                acc[item.symbol] = item.price;
                return acc;
            }, {});
            console.log(priceData);

            setPrices(priceData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchPrices();

        const interval = setInterval(fetchPrices, 10000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    useEffect(() => {
        if (account) {
            const fetchUnreadMessagesCount = async () => {
                try {
                    const response = await axiosGet('api/chats/unread-messages-count', account);
                    console.log("unread-messages-count", response);
                    setUnreadCount(response.unreadCount);
                } catch (error) {
                    console.error('Error fetching unread message count:', error);
                }
            };
            const intervalId = setInterval(fetchUnreadMessagesCount, 5000);
            fetchUnreadMessagesCount();

            return () => clearInterval(intervalId);
        }
    }, [account]);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh', backgroundColor: 'black', color: '#39FF14' }}>
            <AppBar position="fixed" sx={{ backgroundColor: 'black', color: '#39FF14' }}>
                <Toolbar
                    sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} // Add Flexbox properties here
                >
                    <div>
                        <IconButton onClick={handleLogoClick}>
                            <img
                                src={platforms[4].logo}
                                alt="Logo"
                                style={{ width: "35px", height: "35px", borderRadius: "50%", marginRight: "8px" }}
                            />
                            <Typography variant="h1" sx={{ color: "#39FF14", fontWeight: "bold", fontSize: "1.4rem" }}>
                                {platforms[4].name}
                            </Typography>
                        </IconButton>
                        <Menu
                            anchorEl={logoMenuAnchorEl}
                            open={Boolean(logoMenuAnchorEl)}
                            onClose={handleCloseLogoMenu}
                        >
                            {
                                platforms?.map((x, i) => {
                                    return (<MenuItem key={i} onClick={handleCloseLogoMenu}>
                                        <NavLink to={x.link} style={{ textDecoration: "none", color: "#39FF14" }}>
                                            <IconButton onClick={handleLogoClick}>
                                                <img
                                                    src={platforms[i].logo}
                                                    alt="Logo"
                                                    style={{ width: "35px", height: "35px", borderRadius: "50%", marginRight: "8px" }}
                                                />
                                                <Typography variant="h1" sx={{ color: "#39FF14", fontWeight: "bold", fontSize: "1.4rem" }}>
                                                    {platforms[i].name}
                                                </Typography>
                                            </IconButton>
                                        </NavLink>
                                    </MenuItem>)
                                })
                            }
                        </Menu>
                    </div>

                    <div className="desktopNavbar">
                        {navLinks.map((x, i) => (
                            <NavLink to={x.to} key={i}>
                                <Link
                                    sx={{
                                        marginLeft: 2,
                                        textDecoration: "none",
                                        color: "#39FF14",
                                        backgroundColor: isActiveLink(x.to)
                                            ? "#444"
                                            : "transparent",
                                        "&:hover": {
                                            backgroundColor: "#555",
                                        },
                                        padding: "8px 16px",
                                        borderRadius: "4px",
                                        border: "4px dashed",
                                    }}>
                                    {x.text}
                                </Link>
                            </NavLink>
                        ))}
                        <CustomConnectButton />
                    </div>

                    <div className="mobileNavbar">
                        <CustomConnectButton />
                        <IconButton edge="end" color="inherit" onClick={toggleDrawer(true)}>
                            <MenuIcon />
                        </IconButton>
                    </div>
                </Toolbar>

            </AppBar>

            <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
                <Box
                    sx={{ width: 250 }}
                    role="presentation"
                    onClick={toggleDrawer(false)}
                    onKeyDown={toggleDrawer(false)}
                >
                    {drawerLinks}
                </Box>
            </Drawer>

            <Box
                component="main"
                sx={{
                    flex: 1,
                    marginTop: '70px',
                    paddingBottom: '120px',
                    overflowY: 'auto',
                }}
            >
                <Box
                    sx={{
                        width: '100%',
                        padding: 2,
                    }}
                >
                    {connected ? children : (
                        <Typography variant="h1" sx={{ marginBottom: 2, textAlign: 'center' }}>
                            Connect your wallet!
                        </Typography>
                    )}
                </Box>
                <Box sx={{ textAlign: 'center', backgroundColor: '#333', color: '#39FF14' }}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: 1,
                            flexWrap: 'wrap',
                            border: "2px dashed"
                        }}
                    >
                        {/* <Typography variant="body2" sx={{ margin: 1, flexBasis: { xs: '100%', sm: 'auto' }, textAlign: 'center' }}>BTC: ${prices.BTC || 'Loading...'}</Typography> */}
                        <Typography variant="body2" sx={{ margin: 1, flexBasis: { xs: '100%', sm: 'auto' }, textAlign: 'center' }}>ETH: ${prices.ETH || 'Loading...'}</Typography>
                        <Typography variant="body2" sx={{ margin: 1, flexBasis: { xs: '100%', sm: 'auto' }, textAlign: 'center' }}>ARB: ${prices.ARB || 'Loading...'}</Typography>
                        <Typography variant="body2" sx={{ margin: 1, flexBasis: { xs: '100%', sm: 'auto' }, textAlign: 'center' }}>APE: ${prices.APE || 'Loading...'}</Typography>
                        <Typography variant="body2" sx={{ margin: 1, flexBasis: { xs: '100%', sm: 'auto' }, textAlign: 'center' }}>SOL: ${prices.SOL || 'Loading...'}</Typography>
                    </Box>
                </Box>
                {
                    location.pathname === '/' &&
                    <Box
                        sx={{
                            display: 'grid',
                            gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr' },
                            gap: 2,
                            marginTop: 2,
                        }}
                    >
                        {/* BTC Chart */}
                        {/* <Card sx={{ width: '100%', height: '350px' }}>
                            <CardHeader
                                title="BTC Chart"
                                sx={{
                                    textAlign: 'center',
                                    backgroundColor: '#333',
                                    color: '#39FF14',
                                    padding: 2,
                                }}
                            />
                            <Box sx={{ width: '100%', height: '100%' }}>
                                <Chart symbol="BTC" />
                            </Box>
                        </Card> */}

                        {/* ETH Chart */}
                        <Card sx={{ width: '100%', height: '350px', border: "2px dashed" }}>
                            <CardHeader
                                title="ETH Chart"
                                sx={{
                                    textAlign: 'center',
                                    backgroundColor: '#333',
                                    color: '#39FF14',
                                    padding: 2,
                                }}
                            />
                            <Box sx={{ width: '100%', height: '100%' }}>
                                <Chart symbol="ETH" />
                            </Box>
                        </Card>

                        {/* ARB Chart */}
                        <Card sx={{ width: '100%', height: '350px', border: "2px dashed" }}>
                            <CardHeader
                                title="ARB Chart"
                                sx={{
                                    textAlign: 'center',
                                    backgroundColor: '#333',
                                    color: '#39FF14',
                                    padding: 2,
                                }}
                            />
                            <Box sx={{ width: '100%', height: '100%' }}>
                                <Chart symbol="ARB" />
                            </Box>
                        </Card>

                        {/* APE Chart */}
                        <Card sx={{ width: '100%', height: '350px', border: "2px dashed" }}>
                            <CardHeader
                                title="APE Chart"
                                sx={{
                                    textAlign: 'center',
                                    backgroundColor: '#333',
                                    color: '#39FF14',
                                    padding: 2,
                                }}
                            />
                            <Box sx={{ width: '100%', height: '100%' }}>
                                <Chart symbol="APE" />
                            </Box>
                        </Card>

                        {/* SOL Chart */}
                        <Card sx={{ width: '100%', height: '350px', border: "2px dashed" }}>
                            <CardHeader
                                title="SOL Chart"
                                sx={{
                                    textAlign: 'center',
                                    backgroundColor: '#333',
                                    color: '#39FF14',
                                    padding: 2,
                                }}
                            />
                            <Box sx={{ width: '100%', height: '100%' }}>
                                <Chart symbol="SOL" />
                            </Box>
                        </Card>
                    </Box>
                }
            </Box>
            <ChatButton unreadCount={unreadCount} />
            <Box
                component="footer"
                sx={{
                    textAlign: 'center',
                    backgroundColor: 'black',
                    color: '#39FF14',
                }}
            >
                {/* <Typography variant='caption'>Each correct prediction results in a 20% protocol fee.</Typography> */}
                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 1 }}>
                    <Link href="https://smooviephone.com/" sx={{ margin: 1, color: '#39FF14' }}>
                        <Language />
                    </Link>
                    <Link href="https://x.com/smooviephone?s=21" sx={{ margin: 1, color: '#39FF14' }}>
                        <Twitter />
                    </Link>
                    <Link href="https://t.me/smooviephonehotline" sx={{ margin: 1, color: '#39FF14' }}>
                        <Telegram />
                    </Link>
                </Box>
            </Box>
        </Box >
    );
};

export default Layout;
