import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { readContract } from '@wagmi/core';
import React, { useEffect, useState } from 'react';
import { bettingContractConfig } from './config';
import { useLoader } from './context/LoaderContext';
import Layout from './layouts/main';
import { handleMetaMaskError } from './utilities/handleMetaMaskError';
import useNotification from './utilities/notificationUtils';
import { wagmiConfig } from './wagmiConfig';


interface PlayerData {
    address: string;
    wins: number;
    losses: number;
}

const Leaderboard: React.FC = () => {
    const { showLoader, hideLoader } = useLoader();
    const { showError } = useNotification();

    const [leaderboardData, setLeaderboardData] = useState<PlayerData[]>([]);
    const [loading, setLoading] = useState(true);

    const fetchLeaderboardData = async () => {
        showLoader();
        setLoading(true);

        try {
            const data = await readContract(wagmiConfig, {
                address: bettingContractConfig.contractAddress,
                abi: bettingContractConfig.abi,
                functionName: 'getAllLeaderboardStats',
            });

            const [playersList, wins, losses] = data as any;

            // Remove duplicates based on player addresses
            const uniquePlayersMap = new Map<string, { wins: number; losses: number }>();

            playersList.forEach((player: string, index: number) => {
                if (!uniquePlayersMap.has(player)) {
                    uniquePlayersMap.set(player, {
                        wins: Number(wins[index]),
                        losses: Number(losses[index]),
                    });
                }
            });

            // Convert the map back to an array of PlayerData
            const playersData: PlayerData[] = Array.from(uniquePlayersMap, ([address, stats]) => ({
                address,
                wins: stats.wins,
                losses: stats.losses,
            }));

            // Sort players based on the difference between wins and losses
            playersData.sort((a, b) => {
                const scoreA = a.wins - a.losses;
                const scoreB = b.wins - b.losses;
                return scoreB - scoreA;
            });

            setLeaderboardData(playersData);
        } catch (error) {
            console.error("Error fetching leaderboard:", error);
            showError(handleMetaMaskError(error));
        } finally {
            hideLoader();
            setLoading(false);
        }
    };


    useEffect(() => {
        fetchLeaderboardData();
        const intervalId = setInterval(fetchLeaderboardData, 30000);

        return () => clearInterval(intervalId);
    }, []);

    const getRank = (index: number, player: PlayerData, previousPlayer: PlayerData | null) => {
        if (index === 0) return 1;
        const score = player.wins - player.losses;
        const previousScore = previousPlayer ? previousPlayer.wins - previousPlayer.losses : null;

        if (previousScore !== null && score === previousScore) {
            return index;
        }
        return index + 1;
    };

    return (
        <Layout>
            <Typography variant="h1" component="h1" gutterBottom>
                Leaderboard
            </Typography>
            {loading ? (
                <Typography variant="body1">Loading...</Typography>
            ) : (
                <TableContainer component={Paper}>
                    <Table sx={{ border: '2px dashed' }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Rank</TableCell>
                                <TableCell>Player Address</TableCell>
                                <TableCell>Game Wins</TableCell>
                                <TableCell>Game Losses</TableCell>
                                <TableCell>Score</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody sx={{ border: '2px dashed' }}>
                            {leaderboardData.map((player, index) => {
                                const previousPlayer = leaderboardData[index - 1] || null;
                                const rank = getRank(index, player, previousPlayer);
                                const score = player.wins - player.losses;

                                return (
                                    <TableRow key={player.address}>
                                        <TableCell>{rank}</TableCell>
                                        <TableCell>{player.address}</TableCell>
                                        <TableCell>{player.wins}</TableCell>
                                        <TableCell>{player.losses}</TableCell>
                                        <TableCell>{score}</TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </Layout>
    );
};

export default Leaderboard;
