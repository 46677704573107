import { Chain, getDefaultConfig } from "@rainbow-me/rainbowkit";

const sanko = {
    id: Number(`${process.env.REACT_APP_CHAIN_ID}` || 0),
    name: 'APE',
    iconUrl: 'https://icons.defined.fi/networks/apechain.png',
    nativeCurrency: { name: 'APE', symbol: 'APE', decimals: 18 },
    rpcUrls: {
        default: { http: [`${process.env.REACT_APP_RPC}`] },
    }
} as const satisfies Chain;

export const wagmiConfig = getDefaultConfig({
    appName: 'APE420',
    projectId: '928e51a9f905957f8e9756f92cd56d2d',
    chains: [sanko]
});