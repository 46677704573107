import {
    Box,
    Button,
    MenuItem,
    TextField,
    Typography
} from '@mui/material';
import { readContract, waitForTransactionReceipt, writeContract } from '@wagmi/core';
import { ethers } from 'ethers';
import { useEffect, useState } from 'react';
import { bettingContractConfig, tokenContractConfig } from './config';
import { useLoader } from './context/LoaderContext';
import { useWallet } from './context/WalletContext';
import Layout from './layouts/main';
import { TokenEnumMapping } from './shared';
import { handleMetaMaskError } from './utilities/handleMetaMaskError';
import useNotification from './utilities/notificationUtils';
import { wagmiConfig } from './wagmiConfig';
import { getToken } from './shared/getToken';

export default function Admin() {
    const { account } = useWallet();
    const [apeRewardPool, setApeRewardPool] = useState<number>(0);
    const [selectedToken, setSelectedToken] = useState('SP');
    const [amount, setAmount] = useState(0);
    const { showSuccess, showError } = useNotification();
    const { showLoader, hideLoader } = useLoader();
    const [isPaused, setIsPaused] = useState<boolean>(false);

    // Approve Token Function
    // const approveToken = async (amount: bigint) => {
    //     try {
    //         console.log(getToken(TokenEnumMapping[selectedToken]));

    //         const hash = await writeContract(wagmiConfig, {
    //             address: getToken(TokenEnumMapping[selectedToken]).address,
    //             abi: tokenContractConfig.abi, // ABI for the token contract
    //             functionName: 'approve',
    //             args: [bettingContractConfig.contractAddress, amount],
    //         });
    //         await waitForTransactionReceipt(wagmiConfig, {
    //             hash
    //         });
    //     } catch (error) {
    //         throw error;
    //     }
    // };

    // Add to Reward Pool Function
    const addToRewardPool = async () => {
        if (!(selectedToken.length > 0 && amount > 0)) {
            showError('Invalid input');
            return;
        }

        const betAmount = ethers.parseUnits(amount.toString(), getToken(TokenEnumMapping[selectedToken]).decimals);

        try {
            showLoader();
            // await approveToken(betAmount);

            const tx = await writeContract(wagmiConfig, {
                address: bettingContractConfig.contractAddress,
                abi: bettingContractConfig.abi,  // ABI for the betting contract
                functionName: 'addToRewardPool',
                value: betAmount
            });

            await waitForTransactionReceipt(wagmiConfig, {
                hash: tx
            });

            showSuccess(`Added ${amount} to the reward pool successfully`);
            await init();
        } catch (error) {
            showError(handleMetaMaskError(error));
        } finally {
            hideLoader();
        }
    };

    // Withdraw Function
    const withdraw = async () => {
        if (!(selectedToken.length > 0 && amount > 0)) {
            showError('Invalid input');
            return;
        }

        const betAmount = ethers.parseUnits(amount.toString(), getToken(TokenEnumMapping[selectedToken]).decimals);

        try {
            showLoader();

            const tx = await writeContract(wagmiConfig, {
                address: bettingContractConfig.contractAddress,
                abi: bettingContractConfig.abi,
                functionName: 'withdrawRewardPool',
                args: [betAmount],
            });

            await waitForTransactionReceipt(wagmiConfig, {
                hash: tx
            });
            showSuccess(`Withdraw successfully`);
            await init();
        } catch (error) {
            showError(handleMetaMaskError(error));
        } finally {
            hideLoader();
        }
    };

    // Toggle Pause Function
    const togglePause = async () => {
        try {
            showLoader();

            const functionName = isPaused ? 'unpause' : 'pause';
            const tx = await writeContract(wagmiConfig, {
                address: bettingContractConfig.contractAddress,
                abi: bettingContractConfig.abi, // ABI for the betting contract
                functionName,
            });

            await waitForTransactionReceipt(wagmiConfig, {
                hash: tx
            });
            showSuccess(isPaused ? 'Contract unpaused successfully' : 'Contract paused successfully');
            setIsPaused(!isPaused);
        } catch (error) {
            showError(handleMetaMaskError(error));
        } finally {
            hideLoader();
        }
    };

    const init = async () => {
        try {
            showLoader();

            const apeRewardPool: any = await readContract(wagmiConfig, {
                address: bettingContractConfig.contractAddress,
                abi: bettingContractConfig.abi,
                functionName: 'apeRewardPool',
            });


            const paused: any = await readContract(wagmiConfig, {
                address: bettingContractConfig.contractAddress,
                abi: bettingContractConfig.abi,
                functionName: 'paused',
            });
            setIsPaused(paused);
            setApeRewardPool(Number(ethers.formatUnits(apeRewardPool, getToken(0).decimals)));
        } catch (error: any) {
            showError(handleMetaMaskError(error));
        } finally {
            hideLoader();
        }
    }

    useEffect(() => {
        if (account) {
            init();
        }
    }, [account]);

    return (
        <Layout>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                    textAlign: 'center',
                    backgroundColor: 'black',
                    padding: 2
                }}
            >
                <Typography variant="h3" sx={{ border: '2px dashed', padding: '5px', marginBottom: '10px' }}>APE Reward Pool: {apeRewardPool} {getToken(0).symbol}</Typography>

                {/* <TextField
                    select
                    label="Select Token"
                    value={selectedToken}
                    onChange={(e) => setSelectedToken(e.target.value)}
                    sx={{ marginBottom: 2, width: 350, marginTop: 2 }}
                >
                    <MenuItem value="APE">AP Token</MenuItem>
                    <MenuItem value="SILVER">Silver Token</MenuItem>
                    <MenuItem value="GOLD">Gold Token</MenuItem>
                </TextField> */}
                <TextField
                    type="number"
                    label="Amount"
                    value={amount}
                    onChange={(e) => setAmount(Number(e.target.value))}
                    sx={{ marginBottom: 2, width: 350 }}
                />

                <Button
                    variant="contained"
                    sx={{
                        marginTop: 3,
                    }}
                    onClick={addToRewardPool}
                >
                    Add to Reward Pool
                </Button>
                <Button
                    variant="contained"
                    sx={{
                        marginTop: 3,
                    }}
                    onClick={withdraw}
                >
                    Withdraw
                </Button>

                <Button
                    variant="contained"
                    sx={{
                        marginTop: 3,
                        backgroundColor: isPaused ? 'red' : 'green'
                    }}
                    onClick={togglePause}
                >
                    {isPaused ? 'Unpause Contract' : 'Pause Contract'}
                </Button>
            </Box>
        </Layout>
    );
}
