import {
    Container,
    Paper,
    Typography
} from '@mui/material';
import Layout from './layouts/main';

const Help = () => {
    return (
        <Layout>
            <Container maxWidth="sm" sx={{ mt: 4, mb: 4 }}>
                <Paper elevation={3} sx={{ padding: 2 }}>
                    <Typography variant="h2" component="h1" gutterBottom>
                        Game Rules
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Deposit $SP or $Silver Token. If you win, you will receive 1.6X of your deposit. Prices for BTC, ETH, SOL, and ARB are fed live from Defined.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        By using this protocol, you agree to these terms. This protocol may be restricted by your country; make sure your country does not restrict access to crypto trading platforms of this nature. All rights reserved.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Smoove Mobile Sim Card Required to Play.
                    </Typography>
                    <Typography variant="h6" component="h2" gutterBottom>
                        Good Luck and Keep Buzzing!
                    </Typography>
                </Paper>
            </Container>
        </Layout>
    );
};

export default Help;
